const modals = document.getElementsByClassName('modal');
const className = 'modal--show';

for (let modal of modals) {
  modal
    .getElementsByClassName('modal__close-button')[0]
    .addEventListener('click', () => {
      if (modal.classList.contains(className)) {
        modal.classList.remove(className);
      } else {
        modal.classList.add(className);
      }
    });
}