export const isBrowser = typeof window !== 'undefined'

export function remToPx(rem: string | number) {
    if (typeof rem === 'string' && rem.indexOf('rem') !== -1) {
        rem = rem.split('rem').join('')
    }

    let fontSize = 16

    if (typeof window !== 'undefined') {
        fontSize = parseFloat(
            window.getComputedStyle(document.documentElement).fontSize
        )
    }

    return Number(rem) * fontSize
}

export function hasClass(element: Element | SVGElement, className: string) {
    if (element.classList)
        return !!className && element.classList.contains(className)

    return (
        ` ${element.className.baseVal || element.className} `.indexOf(
            ` ${className} `
        ) !== -1
    )
}

export function removeClass(element: Element | SVGElement, className: string) {
    if (element?.classList) {
        element.classList?.remove(className)
    } else if (typeof element.className === 'string') {
        ;(element as Element).className = replaceClassName(
            element.className,
            className
        )
    } else {
        element.setAttribute(
            'class',
            replaceClassName(
                (element.className && element.className.baseVal) || '',
                className
            )
        )
    }
}

function replaceClassName(origClass: string, classToRemove: string) {
    return origClass
        .replace(new RegExp(`(^|\\s)${classToRemove}(?:\\s|$)`, 'g'), '$1')
        .replace(/\s+/g, ' ')
        .replace(/^\s*|\s*$/g, '')
}

export function addClass(element: Element | SVGElement, className: string) {
    if (element.classList) element.classList.add(className)
    else if (!hasClass(element, className))
        if (typeof element.className === 'string')
            (element as Element).className = `${element.className} ${className}`
        else
            element.setAttribute(
                'class',
                `${(element.className && element.className.baseVal) ||
                ''} ${className}`
            )
}