import {addClass, removeClass} from "@frontendUtils/helpers";
import {rawSizes} from "@frontendConstants/rawSizes";
import {togglebackdropBlur} from "@apps/Shared/Utils/Utils";

function toggleOpenMenu() {
    const menu = document.querySelector('.header-bottom__navigation') as HTMLElement;
    menu.classList.toggle('menuOpen');
    const menuIcon = document.querySelector('.menu-icon') as HTMLElement;
    menuIcon.classList.toggle('menuOpen');
    if (menu.classList.contains('menuOpen')) {
        addClass(document.body, 'mobile-menu-open');
        togglebackdropBlur(true);
    } else {
        removeClass(document.body, 'mobile-menu-open');
        togglebackdropBlur(false);
        closeAllMenuItems();
        closeAllMenuLevel2Items();
    }
}

function toggleExpandMenu(menuItem: HTMLElement) {
    const smallDevice = window.innerWidth <= rawSizes.xl

    if (!smallDevice) {
        return;
    }
    menuItem.classList.toggle('expanded');
    menuItem.querySelector('.button--expandable')?.classList.toggle('expanded');
    menuItem.querySelector('.expandable__icon')?.classList.toggle('expanded');
    menuItem.querySelector('.navigation-container__sub-menu-level')?.classList.toggle('expanded');
    const spanButtonElement = menuItem.querySelector("span:first-of-type") as HTMLElement;
    const expanded = spanButtonElement.getAttribute('aria-expanded')
    if (expanded === 'false') {
        spanButtonElement.setAttribute('aria-expanded', 'true')
    } else {
        spanButtonElement.setAttribute('aria-expanded', 'false')
    }

    // Remove 'expanded' class from other menu items
    closeAllMenuItems(menuItem);
    closeAllMenuLevel2Items();   
}

function closeAllMenuItems(exceptThisMenuItem?: HTMLElement) {
    const allMenuItems = document.querySelectorAll('.navigation-container__menu-item');
    allMenuItems.forEach((otherItem) => {
        if (otherItem !== exceptThisMenuItem) {
            otherItem.classList.remove('expanded');
            otherItem.querySelector('.button--expandable')?.classList.remove('expanded');
            otherItem.querySelector('.expandable__icon')?.classList.remove('expanded');
            otherItem.querySelector('.navigation-container__sub-menu-level')?.classList.remove('expanded');
            const otherSpanButtonElement = otherItem.querySelector("span:first-of-type") as HTMLElement;
            otherSpanButtonElement.setAttribute('aria-expanded', 'false');
        }
    });
}

function toggleExpandMenuLevel(expandableButton: HTMLElement) {
    const smallDevice = window.innerWidth <= rawSizes.xl
    if (!smallDevice) {
        return;
    }

    expandableButton.classList.toggle('expanded');
    expandableButton.querySelector('.expandable__icon')?.classList.toggle('expanded');
    expandableButton.parentElement?.querySelector('.navigation-container__sub-menu-level')?.classList.toggle('expanded');

    closeAllMenuLevel2Items(expandableButton);
}

function closeAllMenuLevel2Items(exceptThisElement?: HTMLElement) {
    const allExpandableButtons = document.querySelectorAll('.expandable-icon.menu-level-2');
    allExpandableButtons.forEach((otherItem) => {
        if (otherItem !== exceptThisElement) {
            otherItem.classList.remove('expanded');
            otherItem.querySelector('.expandable__icon')?.classList.remove('expanded');
            otherItem.parentElement?.querySelector('.navigation-container__sub-menu-level')?.classList.remove('expanded');
        }
    });
}

function setMenuOpen(open: boolean) {
    const menu = document.querySelector('.header-bottom__navigation') as HTMLElement;
    const menuIcon = document.querySelector('.menu-icon') as HTMLElement;
    if (open) {
        menu.classList.add('menuOpen');
        menuIcon.classList.add('menuOpen');
        addClass(document.body, 'mobile-menu-open');
        togglebackdropBlur(true);
    } else {
        menu.classList.remove('menuOpen');
        menuIcon.classList.remove('menuOpen');
        removeClass(document.body, 'mobile-menu-open');
        togglebackdropBlur(false);
    }
}

function HandleKeyUpEvent(e: any) {
    if (e.keyCode === 27) { //esc key
        setTimeout(() => {
            setMenuOpen(false)
        }, 200)
    }
}

function HandleWindowResize() {
    const smallDevice = window.innerWidth <= rawSizes.xl

    if (!smallDevice) {
        setMenuOpen(false)
    }
}

function HandleOutsideClick(e: MouseEvent) {
    const isMenuButton = (e.target as HTMLElement).classList.contains('header-bottom__menu-button');
    const isMenuButtonIcon = (e.target as HTMLElement).classList.contains('menu-icon');
    if (isMenuButton || isMenuButtonIcon) {
        return;
    }
    const headerNavigationRef = document.querySelector('.header-bottom__navigation') as HTMLElement;
    const menu = document.querySelector('.header-bottom__navigation') as HTMLElement;

    // Check if the click event target is inside the menu
    const isClickInside = headerNavigationRef && headerNavigationRef.contains(e.target as Node);

    // If the click was outside the menu and the menu is open, close the menu
    if (!isClickInside && menu?.classList?.contains('menuOpen')) {
        setMenuOpen(false);
    }

    const smallDevice = window.innerWidth <= rawSizes.xl
    if (smallDevice) {
        const menu = document.querySelector('.navigation-container__root-level') as HTMLElement;
        const isClickInside = menu.contains(e.target as Node);
        if (!isClickInside) {
            closeAllMenuItems();
            closeAllMenuLevel2Items();
        }
    }
}

document.addEventListener('DOMContentLoaded', function () {
    const menuButton = document.querySelector('.header-bottom__menu-button') as HTMLElement;
    menuButton?.addEventListener('click', toggleOpenMenu);    

    const expandableButtons = document.querySelectorAll(".expandable-icon.menu-level-2");
    expandableButtons?.forEach((expandableButton) => {
        expandableButton?.addEventListener('click', () => {
            toggleExpandMenuLevel(expandableButton as HTMLElement);
        });
    });

    const menuItems = document.querySelectorAll('.navigation-container__menu-item > span:first-of-type');
    menuItems?.forEach((menuItem) => {
        menuItem?.addEventListener('click', () => {
            const menuItemParent = menuItem.parentElement as HTMLElement;
            toggleExpandMenu(menuItemParent as HTMLElement);
        });
        menuItem?.addEventListener('keyup', (e) => {
            const keyboardEvent = e as KeyboardEvent
            if (keyboardEvent.key == "Enter")
            {
                const menuItemParent = menuItem.parentElement as HTMLElement;
                toggleExpandMenu(menuItemParent as HTMLElement);
            }
        });
    });

    const menuItemButtons = document.querySelectorAll('.navigation-container__menu-item > button:first-of-type');
    menuItemButtons?.forEach((menuItemButton) => {
        menuItemButton?.addEventListener('click', () => {
            const menuItemParent = menuItemButton.parentElement as HTMLElement;
            toggleExpandMenu(menuItemParent as HTMLElement);
        });
    });

    document.addEventListener('keyup', HandleKeyUpEvent)
    document.addEventListener('click', HandleOutsideClick)
    window.addEventListener('resize', HandleWindowResize)
});