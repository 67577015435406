document.addEventListener("DOMContentLoaded", () => {
    const choiceContainers = document.querySelectorAll(".language-selection__choice-container");
    const iconHTML = '<span class="icon-check panicon"></span>';

    choiceContainers.forEach((container) => {
        const anchor = container.querySelector(".language-selection__link") as HTMLAnchorElement;
        const radioButton = container.querySelector(".language-selection__radio-button") as HTMLDivElement;

        if (anchor && radioButton) {
            anchor.addEventListener("click", () => {

                // Remove the "checked" class and icon from all radio buttons in the group
                document.querySelectorAll(".language-selection__radio-button").forEach((el) => {
                    el.classList.remove("checked");
                    const icon = el.querySelector(".icon-check.panicon");
                    if (icon) {
                        icon.remove();
                    }
                });

                // Add the "checked" class and icon to the clicked radio button
                radioButton.classList.add("checked");
                radioButton.innerHTML += iconHTML;

                // Update aria-checked attribute for accessibility
                choiceContainers.forEach((choice) => {
                    const isSelected = choice === container;
                    choice.setAttribute("aria-checked", isSelected.toString());
                });
            });
        }
    });
});

document.addEventListener("DOMContentLoaded", () => {
    // Select all choice containers
    const choiceContainers = document.querySelectorAll(".language-selection__choice-container");

    choiceContainers.forEach((container) => {
        const anchor = container.querySelector(".language-selection__link") as HTMLAnchorElement;

        if (anchor) {
            // Add a click event to the entire container
            container.addEventListener("click", () => {
                // Trigger the click on the anchor element
                anchor.click();
            });
        }
    });
});

