import map from 'lodash/map'
import replace from 'lodash/replace'
import {LANG_EN} from "@apps/Shared/Constants/Language";
import supportedBrowsers from "@apps/Shared/Utils/supportedBrowsers";
import {Locales} from "@apps/Shared/GraphQL/generated";

export const isBrowser = typeof window !== 'undefined'
export const isIE11 = isBrowser && !!navigator.userAgent.match(/Trident\/7\./)

export const addMinutes = (date: Date, minutes: number) => {
    return new Date(date.getTime() + minutes * 60000)
}

export const removeYears = (date: Date, years: number) => {
    date.setFullYear(date.getFullYear() - years)
    return date
}
export const removeMonths = (date: Date, months: number) => {
    date.setMonth(date.getMonth() - months)
    return date
}
export const removeDays = (date: Date, days: number) => {
    date.setDate(date.getDate() - days)
    return date
}

export const getDateString = (date: Date) => {
    return date.toISOString().substring(0, 10);
}

export const onlyNumbers = (value: string) => {
    if (value) {
        return value.replace(/\D/g, '')
    }
    return value
}

export const removeLeadingZeros = (value: string) => {
    if (value) {
        const intValue = parseInt(value, 10)
        return intValue.toString() || value
    }
    return value
}

export const formatNumber = (value: string, lang: string) => {
    if (value) {
        if (lang === LANG_EN) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
    return value
}

export const formatGramsToKilo = (
    weightInGrams: number,
    lang: string
): string => {
    if (weightInGrams) {
        const weightInKilos = (weightInGrams / 1000).toFixed(2)
        const formattedNumber = formatNumber(weightInKilos, lang)
        const decimalSeparator = lang === LANG_EN ? '.' : ','
        return formattedNumber.replace('.', decimalSeparator)
    }
    return '0'
}

export const stringNumbers = (value: string) => {
    if (value) {
        return value.toString().replace(/\D/g, '')
    }
    return value
}

//Allowing digits, dots and commas
export const stringFloatNumbers = (value: string) => {
    if (value) {
        return value.toString().replace(/[^\d.,]/g, '')
    }
    return value
}

export function krToOre(n: number, lang: string) {
    if (lang === LANG_EN) {
        return (n * 100).toFixed(2) + ' öre'
    }
    return ((n * 100).toFixed(2) + ' öre').split('.').join(',')
}

export function toKr(n: number, lang: string) {
    const roundTwoDecimals = n.toFixed(2) + ''
    if (lang === LANG_EN) {
        return formatNumber(roundTwoDecimals, lang)
    }
    return formatNumber(roundTwoDecimals.split('.').join(','), lang)
}

export function numberToString(n: number) {
    return n
        .toLocaleString('sv-SE')
        .split(',')
        .join(' ')
}

export const formatDateString = (date: string, lang: string) => {
    const datestr = date && date.substr(0, 10)
    if (lang === LANG_EN && datestr) {
        const dateObj = new Date(datestr)
        return (dateObj && dateObj.toLocaleDateString('en-GB')) || datestr
    }
    return datestr
}

export const daysUntil = (date: Date) => {
    if (date) {
        const difference = date.getTime() - new Date().getTime()
        const days = Math.round(difference / (1000 * 3600 * 24))
        if (days >= 0) {
            return days
        }
    }
    return -1
}

export const stringIncludes = (string1: string, string2?: string) =>
    !!(string1.indexOf(string2 || '###') !== -1)

export function parseJson<T>(response: string | null): T {
    return JSON.parse(response || '{}')
}

export const isVisible = (elem: HTMLElement) => {
    return !!(
        elem.offsetWidth ||
        elem.offsetHeight ||
        elem.getClientRects().length
    )
}

export const isAbsoluteUrl = (url: string) => {
    const regex = new RegExp('^(?:[a-z]+:)?//', 'i')
    return regex.test(url)
}

export function hasClass(element: Element | SVGElement, className: string) {
    if (element.classList)
        return !!className && element.classList.contains(className)

    return (
        ` ${element.className.baseVal || element.className} `.indexOf(
            ` ${className} `
        ) !== -1
    )
}

export function addClass(element: Element | SVGElement, className: string) {
    if (element.classList) {
        element.classList.add(className);
    } else if (!hasClass(element, className)) {
        if (typeof element.className === 'string') {
            (element as Element).className = `${element.className} ${className}`
        } else {
            element.setAttribute(
                'class',
                `${(element.className && element.className.baseVal) ||
                ''} ${className}`
            )
        }
    }
}


function replaceClassName(origClass: string, classToRemove: string) {
    return origClass
        .replace(new RegExp(`(^|\\s)${classToRemove}(?:\\s|$)`, 'g'), '$1')
        .replace(/\s+/g, ' ')
        .replace(/^\s*|\s*$/g, '')
}

export function removeClass(element: Element | SVGElement, className: string) {
    if (element.classList) {
        element.classList.remove(className)
    } else if (typeof element.className === 'string') {
        ;(element as Element).className = replaceClassName(
            element.className,
            className
        )
    } else {
        element.setAttribute(
            'class',
            replaceClassName(
                (element.className && element.className.baseVal) || '',
                className
            )
        )
    }
}

export function togglebackdropBlur(show: boolean): void {
    const $body = document.body
    if (!show) {
        removeClass($body, 'backdrop-blur')
    } else {
        addClass($body, 'backdrop-blur')
    }
}

// Values are inserted at {0}, {1} etc.
export const stringFormat = (
    stringToFormat: string,
    valuesToInsert: string[]
) => {
    let formattedString = stringToFormat
    map(valuesToInsert, (value: string, index: number) => {
        const regex = new RegExp(`\\{${index}\\}`, 'g')
        formattedString = replace(formattedString, regex, value)
    })
    return formattedString
}

export const inArray = (needle: string, haystack: Array<any>) => {
    return haystack.includes(needle)
}

export function remToPx(rem: string | number) {
    if (typeof rem === 'string' && rem.indexOf('rem') !== -1) {
        rem = rem.split('rem').join('')
    }

    let fontSize = 16

    if (typeof window !== 'undefined') {
        fontSize = parseFloat(
            window.getComputedStyle(document.documentElement).fontSize
        )
    }

    return Number(rem) * fontSize
}

export function resize() {
    if (isIE11) {
        const evt = document.createEvent('UIEvents') as any
        evt.initUIEvent('resize', true, false, window, 0)

        window.dispatchEvent(evt)
    } else {
        window.dispatchEvent(new Event('resize'))
    }
}

export const extractValidationErrors = (errorObject: any) => {
    const errors = []
    if (errorObject) {
        for (const key in errorObject) {
            const value = errorObject[key]

            if (value && value.length > 0) {
                errors.push(`- ${value[0]}`)
            }
        }
    }
    return errors
}

export const isBrowserSupported = () => {
    if (isIE11) {
        return false
    }

    try {
        if (
            navigator &&
            supportedBrowsers &&
            supportedBrowsers.test(navigator.userAgent)
        ) {
            return true
        } else {
            return false
        }
    } catch (e) {
        // We don't want pageload to break solely on the failure of this operation

        console.log('isBrowserSupported failed:', e)

        return true
    }
}

export const getLocale = () => {
    const languageInput = document.getElementById('language') as HTMLInputElement;
    const locale = languageInput ? languageInput.value : 'sv';

    let mappedLocale;
    switch (locale) {
        case 'sv':
            mappedLocale = Locales.Sv;
            break;
        case 'en':
            mappedLocale = Locales.En;
            break;
        default:
            mappedLocale = Locales.Sv;
    }

    return mappedLocale;
}

export const isExternalLink = (url: string) => {
    const currentDomain = window.location.origin;
    
    return url.startsWith('http://') || url.startsWith('https://') && !url.startsWith(currentDomain);
}

export const isDownloadLink = (url: string) => {
    const downloadExtensions = ['.pdf', '.zip', '.docx', '.xlsx', '.pptx', '.jpg', '.png', '.mp4'];
    return downloadExtensions.some(ext => url.toLowerCase().endsWith(ext));
}
