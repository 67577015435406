export const initializeLogoutForms = (): void => {
    const logoutForms = document.querySelectorAll<HTMLFormElement>('.logout-form');

    logoutForms.forEach((form) => {
        form.addEventListener('submit', (event) => {
            // Clear session storage
            sessionStorage.clear();
        });
    });
};

document.addEventListener('DOMContentLoaded', () => {
    initializeLogoutForms();
});